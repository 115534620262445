var generic = generic || {};
var site = site || {};

(function ($) {
  $(document).on('remainOnAvedaOverlay', function (e, navigateLink) {
    var overlaySection = site.template.get({ name: 'remain_overlay' });

    generic.overlay.launch({
      html: overlaySection,
      onComplete: function () {
        var $overlay = $('.js-remain-on-aveda');
        var $overlayButton = $('.js-remain-on-aveda-cta a', $overlay);
        var $overlayLink = $('.js-remain-on-aveda-link', $overlay);

        $overlayButton.attr('href', navigateLink);
        $overlayLink.on('click', function () {
          $.colorbox.close();
        });
      }
    });
  });
})(jQuery);
